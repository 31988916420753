<template>
  <v-app>
    <v-row align="center">
      <v-col cols="10" class="mx-auto mb-12">
        <h2 class="mb-5 display-2">ABOUT THE PLATFORM</h2>
        <p class="mb-5">
          The Plateau State Contributory Healthcare Management Agency (PLASCHEMA) aims to strengthen health financing by pooling health resources and reduce out-of-pocket expenditure through strategic purchasing.
          Strengthening the health insurance system in Plateau State is integral to achieving this aim.
          The OpenHISA platform is one of PLASCHEMA’s major efforts in this direction. OpenHISA stands for Open Health Insurance System and Analytics.
        </p>
        <p>
          The OpenHISA platform is a solution to the health insurance coverage in Plateau State. A mobile, web-based platform, whose reach can be extended to all relevant populations.
          The OpenHISA platform is customized to fit a wide range of users’ needs. With the platform, users can:
        </p>
        <ul class="mb-12">
          <li v-for="text in texts" :key="text">{{text}}</li>
        </ul>
        <p class="mt-12">
          OpenHISA is developed by ehealth4everyone, a digital health enterprise dedicated to making the world healthier. For more information on what we do and our products, please click
          <a
            href="http://ehealth4everyone.com"
            target="_blank"
          >here</a>,
        </p>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
export default {
  name: "about",
  data() {
    return {
      texts: [
        "Register for health insurance plans.",
        "Create a personalized health insurance dashboard.",
        "Select personally suited health insurance plans.",
        "Make payment for health insurance plans.",
        "Make a choice of health facilities.",
        "Manage patient claims.",
        "Manage enrollment into insurance plans."
      ]
    };
  }
};
</script>
